.search-box {
  position: relative;
  width: 300px;

  input {
    width: 100%;
    padding: 10px 20px;
    border: 2px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #007bff;
    }

    &::placeholder {
      color: #aaa;
    }
  }
}
